import React from 'react'
import {
  HashRouter as Router,
  Route,
  Link,
  withRouter
} from 'react-router-dom'

import SubscriptionTable from './Members/Subscription/SubscriptionTableComponent'
import WODTable from './Workouts/WOD/WODTableComponent'
import WorkoutTable from './Workouts/Workout/WorkoutTableComponent'
import TrainingPlanTable from './Workouts/TrainingPlan/TrainingPlanTableComponent'
import UserInfoDisplay from './Members/UserInfoDisplayComponent'
import Parse from 'parse'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import LoginForm from './LoginFormComponent'
import Workout from './Workouts/Workout/WorkoutComponent'
import AdminWorkout from './Workouts/Workout/AdminWorkoutComponent'
import WOD from './Workouts/WOD/WODComponent'
import TrainingPlan from './Workouts/TrainingPlan/TrainingPlanComponent'
import Emails from './Members/EmailsComponent'
import EquipmentList from './Workouts/Equipment/EquipmentListComponent.js'
import EquipmentDetail from './Workouts/Equipment/EquipmentDetailComponent.js'
import EffortVariationList from './Workouts/EffortVariation/EffortVariationListComponent.js'
import EffortVariationDetail from './Workouts/EffortVariation/EffortVariationDetailComponent.js'
import FeaturedContentTable from './FeaturedContentTableComponent'
import Videos from './Videos/VideosComponent'
import Video from './Videos/VideoComponent'
import TipOfTheDays from './Videos/TipOfTheDayListComponent'
import TipOfTheDay from './Videos/TipOfTheDayComponent'
import DrylandExercises from './Workouts/Dryland/DrylandExercisesComponent'
import DrylandExerciseComponent from './Workouts/Dryland/DrylandExerciseComponent'
import ResetPassword from './Members/ResetPasswordComponent'
import SwimReport from './Members/SwimReportComponent'
import FeaturedVideoTable from './Videos/FeaturedVideoTableComponent'
import ChallengesList from './ChallengesListComponent'
import AchievementsList from './AchivementsListComponent.js'
import AchievementsDetail from './AchivementsDetailComponent.js'
import Challenge from './ChallengeComponent'
import MemberListComponent from './Members/MemberListComponent.js'
import MemberDetailComponent from './Members/MemberDetailComponent.js'
import CompletedWorkoutComponent from './CompletedWorkoutComponent.js'
import CompletedWorkoutListComponent from './CompletedWorkoutListComponent.js'
import CompletedWorkoutHeatmapComponent from './CompletedWorkoutHeatmapComponent.js'
import ReleaseNotesListComponent from './ReleaseNotesListComponent.js'
import FitFileDebugComponent from './FitFileDebugComponent.js'
import AppleWatchSessionDebugComponent from './AppleWatchSessionDebugComponent.js'
import ReleaseNotesDetailComponent from './ReleaseNotesDetailComponent'
import SkillLevelDetail from './Workouts/SkillLevel/SkillLevelDetailComponent'
import SkillLevelList from './Workouts/SkillLevel/SkillLevelListComponent'
import AdminPlan from './Workouts/TrainingPlan/AdminPlanComponent'
import Config from './config'
import WebTools from './Tools/WebToolsComponent.js'
import DownloadTool from './Tools/DownloadToolComponent.js'
import GenWorkout from './Workouts/genWorkout/GenWorkoutComponent.js'
import GenPlan from './Workouts/genPlan/GenPlanComponent.js'

const LoginFormWithRouter = withRouter(LoginForm)
const WODTableWithRouter = withRouter(WODTable)
const WorkoutTableWithRouter = withRouter(WorkoutTable)
const TrainingPlanTableWithRouter = withRouter(TrainingPlanTable)
const UserInfoDisplayWithRouter = withRouter(UserInfoDisplay)
const FeaturedContentTableWithRouter = withRouter(FeaturedContentTable)
const FeaturedVideoTableWithRouter = withRouter(FeaturedVideoTable)

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute ({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        Parse.User.current() ? children : null
      }
    />
  )
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PublicRoute ({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        (Parse.User.current() == null) ? children : null
      }
    />
  )
}

export default class MainNav extends React.Component {
  constructor (props) {
    Parse.initialize(Config.appId, Config.clientKey)
    Parse.serverURL = Config.serverUrl

    super(props)
    this.state = {
      user: Parse.User.current(),
      currentPage: props.currentPage
    }

    this.handleLogoutButton = this.handleLogoutButton.bind(this)

    console.log('MainNav component loading')
    console.log(Parse.User.current())
  }

  handleLogoutButton = (event) => {
    Parse.User.logOut().then(() => {
      this.setState({
        isLoggedOut: Parse.User.current() == null
      })
    })
  }

  changeEnvironmentToDev = () => {
    Config.setEnvironment('dev')
    window.location.reload(false)
  }

  changeEnvironmentToProd = () => {
    Config.setEnvironment('prod')
    window.location.reload(false)
  }

  render = () => {
    return (
      <Router>
        <div>
          <PrivateRoute>

            <nav className="navbar navbar-expand-lg navbar-dark bg-primary sticky-top">
              <div className="container">

                <a className="navbar-brand" href="/">MySwimPro Admin</a>

                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">

                  <ul className="navbar-nav mr-auto">

                    <li className="nav-item dropdown">
                      <button className="nav-link dropdown-toggle btn-primary" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ color: 'white', border: 'none' }}>
                        Members
                      </button>
                      <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <Link className="btn btn-link dropdown-item" to="/members">Members</Link>
                        <hr />
                        <Link className="btn btn-link dropdown-item" to="/emails">Download Email List</Link>
                        <Link className="btn btn-link dropdown-item" to="/passwords">Password Reset</Link>
                        <Link className="btn btn-link dropdown-item" to="/swimreport">Swim Report</Link>
                      </div>
                    </li>

                    <li className="nav-item dropdown">
                      <button className="nav-link dropdown-toggle btn-primary" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ color: 'white', border: 'none' }}>
                        Workouts
                      </button>
                      <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <Link className="btn btn-link dropdown-item" to="/wods">WODs</Link>
                        <Link className="btn btn-link dropdown-item" to="/adminworkouts">Admin Workouts</Link>
                        <Link className="btn btn-link dropdown-item" to="/workouts">Member Workouts</Link>
                        <hr />
                        <Link className="btn btn-link dropdown-item" to="/exercises">Dryland Exercises</Link>
                        <Link className="btn btn-link dropdown-item" to="/equipment">Equipment</Link>
                        <Link className="btn btn-link dropdown-item" to="/effortvariation">Effort Variation</Link>
                        <Link className="btn btn-link dropdown-item" to="/skilllevels">Skill Levels</Link>
                        <hr />
                        <Link className="btn btn-link dropdown-item" to="/genWorkout">🪄 Workout Generator</Link>
                      </div>
                    </li>

                    <li className="nav-item dropdown">
                      <button className="nav-link dropdown-toggle btn-primary" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ color: 'white', border: 'none' }}>
                        Plans
                      </button>
                      <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <Link className="btn btn-link dropdown-item" to="/adminplans">Admin Plans</Link>
                        <Link className="btn btn-link dropdown-item" to="/trainingplans">Member Plans</Link>
                        <hr />
                        <Link className="btn btn-link dropdown-item" to="/genPlan">🪄 Plan Generator</Link>
                      </div>
                    </li>

                    <li className="nav-item dropdown">
                      <button className="nav-link dropdown-toggle btn-primary" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ color: 'white', border: 'none' }}>
                        Activities
                      </button>
                      <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <Link className="btn btn-link dropdown-item" to="/completedWorkouts">Completed Workouts</Link>
                        <Link className="btn btn-link dropdown-item" to="/heatmap">Heatmap</Link>
                        <hr />
                        <Link className="btn btn-link dropdown-item" to="/fitfiledebug">Fit File Debugger</Link>
                        <Link className="btn btn-link dropdown-item" to="/watchSessionDebug">Apple Watch Session Debugger</Link>
                      </div>
                    </li>

                    <li className="nav-item dropdown">
                      <button className="nav-link dropdown-toggle btn-primary" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ color: 'white', border: 'none' }}>
                        Videos
                      </button>
                      <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <Link className="btn btn-link dropdown-item" to="/videos">Video Library</Link>
                        <Link className="btn btn-link dropdown-item" to="/tipofthedays">Tip of the Day</Link>
                      </div>
                    </li>

                    <li className="nav-item dropdown">
                      <button className="nav-link dropdown-toggle btn-primary" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ color: 'white', border: 'none' }}>
                        Awards
                      </button>
                      <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <Link className="btn btn-link dropdown-item" to="/challenges">Challenges</Link>
                        <Link className="btn btn-link dropdown-item" to="/achievements">Achievements</Link>
                      </div>
                    </li>

                    <li className="nav-item dropdown">
                      <button className="nav-link dropdown-toggle btn-primary" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ color: 'white', border: 'none' }}>
                        Admin Tools
                      </button>
                      <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <Link className="btn btn-link dropdown-item" to="/webtools">Web Tools</Link>
                        <Link className="btn btn-link dropdown-item" to="/downloadtool">Download Link Tool</Link>
                        <Link className="btn btn-link dropdown-item" to="/releasenotes">App Release Notes</Link>
                        <Link className="btn btn-link dropdown-item" to="/featuredcontent">Feed</Link>
                      </div>
                    </li>
                  </ul>
                  <ul className="navbar-nav">
                    <li className="nav-item dropdown">
                      <button className="nav-link dropdown-toggle btn-primary" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ border: 'none' }}>
                        {Config.getEnvironment() === 'dev' ? '🧪 dev' : 'prod'}
                      </button>
                      <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <div className="btn dropdown-item" onClick={this.changeEnvironmentToProd}>Production Database</div>
                        <div className="btn dropdown-item" onClick={this.changeEnvironmentToDev}>Development Database</div>
                      </div>
                    </li>
                    <button className="btn btn-light" onClick={this.handleLogoutButton}>Logout</button>
                  </ul>
                </div>
              </div>
            </nav>

          </PrivateRoute>
          <PrivateRoute path="/workouts/:id" component={Workout} isAdmin={false} />
          <PrivateRoute path="/adminworkouts/:id" component={AdminWorkout} />
          <PrivateRoute path="/challenges/:id" component={Challenge} />
          <PrivateRoute path="/videos/:id" component={Video} />
          <PrivateRoute path="/tipofthedays/:id" component={TipOfTheDay} />
          <PrivateRoute path="/exercises/:id" component={DrylandExerciseComponent} />
          <PrivateRoute path="/equipment/:id" component={EquipmentDetail} />
          <PrivateRoute path="/effortVariation/:id" component={EffortVariationDetail} />
          <PrivateRoute path="/skilllevels/:id" component={SkillLevelDetail} />
          <PrivateRoute path="/wod/:id" component={WOD} isAdmin={true} />
          <PrivateRoute path="/trainingplans/:id" component={TrainingPlan} />
          <PrivateRoute path="/adminplans/:id" component={AdminPlan} />
          <PrivateRoute path="/achievements/:id" component={AchievementsDetail} />
          <PrivateRoute path="/userInfo/:id" component={UserInfoDisplay} />
          <PrivateRoute path="/members/:id" component={MemberDetailComponent} />
          <PrivateRoute path="/completedWorkouts/:id" component={CompletedWorkoutComponent} />
          <PrivateRoute path="/releasenotes/:id" component={ReleaseNotesDetailComponent} />
          <PrivateRoute path="/fitfiledebug" component={FitFileDebugComponent} />
          <PrivateRoute path="/watchSessionDebug" component={AppleWatchSessionDebugComponent} />
          <PrivateRoute path="/webtools" component={WebTools} />
          <PrivateRoute path="/downloadtool" component={DownloadTool} />
          <PrivateRoute exact path="/userInfo">
            <UserInfoDisplayWithRouter />
          </PrivateRoute>
          <PrivateRoute exact path="/members">
            <MemberListComponent />
          </PrivateRoute>
          <PrivateRoute exact path="/completedWorkouts" >
            <CompletedWorkoutListComponent />
          </PrivateRoute>
          <PrivateRoute exact path="/heatmap" >
            <CompletedWorkoutHeatmapComponent />
          </PrivateRoute>
          <PrivateRoute exact path="/releasenotes">
            <ReleaseNotesListComponent />
          </PrivateRoute>
          <PrivateRoute exact path="/subscription">
            <SubscriptionTable subscriptions={[]} />
          </PrivateRoute>
          <PrivateRoute exact path="/wods">
            <WODTableWithRouter wods={[]} />
          </PrivateRoute>
          <PrivateRoute exact path="/workouts">
            <WorkoutTableWithRouter workouts={[]} isAdmin={false} />
          </PrivateRoute>
          <PrivateRoute exact path="/adminworkouts" >
            <WorkoutTableWithRouter workouts={[]} isAdmin={true} />
          </PrivateRoute>
          <PrivateRoute exact path="/trainingplans">
            <TrainingPlanTableWithRouter plans={[]} />
          </PrivateRoute>
          <PrivateRoute exact path="/adminplans" >
            <TrainingPlanTableWithRouter isAdmin={true} />
          </PrivateRoute>
          <PrivateRoute exact path="/genWorkout" >
            <GenWorkout />
          </PrivateRoute>
          <PrivateRoute exact path="/genPlan" >
            <GenPlan />
          </PrivateRoute>
          <PrivateRoute exact path="/featuredcontent">
            <FeaturedContentTableWithRouter featuredContent={[]} />
          </PrivateRoute>
          <PrivateRoute exact path="/emails">
            <Emails />
          </PrivateRoute>
          <PrivateRoute exact path="/passwords">
            <ResetPassword />
          </PrivateRoute>
          <PrivateRoute exact path="/swimreport">
            <SwimReport />
          </PrivateRoute>
          <PrivateRoute exact path="/videos">
            <Videos />
          </PrivateRoute>
          <PrivateRoute exact path="/tipofthedays">
            <TipOfTheDays />
          </PrivateRoute>
          <PrivateRoute exact path="/featuredvideos">
            <FeaturedVideoTableWithRouter featuredVideos={[]} />
          </PrivateRoute>
          <PrivateRoute exact path="/exercises">
            <DrylandExercises />
          </PrivateRoute>
          <PrivateRoute exact path="/equipment">
            <EquipmentList />
          </PrivateRoute>
          <PrivateRoute exact path="/effortvariation">
            <EffortVariationList />
          </PrivateRoute>
          <PrivateRoute exact path="/skilllevels">
            <SkillLevelList />
          </PrivateRoute>
          <PrivateRoute exact path="/challenges">
            <ChallengesList />
          </PrivateRoute>
          <PrivateRoute exact path="/achievements">
            <AchievementsList />
          </PrivateRoute>
          <PublicRoute path="/">
            <LoginFormWithRouter />
          </PublicRoute>
        </div>
      </Router>
    )
  }
}
