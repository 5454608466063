import React, { useEffect, useState } from 'react'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet.heat'
import Parse from 'parse'

const CompletedWorkoutHeatmapComponent = () => {
  let [isLoading, setIsLoading] = useState(false)
  let [numLocations, setNumLocations] = useState(0)

  useEffect(() => {
    // Initialize the map
    const map = L.map('heatmap').setView([39.8283, -98.5795], 4)

    async function loadData () {
      setIsLoading(true)
      const results = await Parse.Cloud.run('getHeatmapLocations')
      console.log(results)
      const locations = results.locations.map(location => {
        return [location.location.latitude, location.location.longitude, location.weight]
      })

      // Add OpenStreetMap tiles
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '© OpenStreetMap contributors'
      }).addTo(map)

      // Create and add the heatmap layer
      const heat = L.heatLayer(locations, {
        radius: 25,
        blur: 15,
        maxZoom: 10,
        max: 1.0,
        gradient: {
          0.4: 'blue',
          0.6: 'lime',
          0.8: 'yellow',
          1.0: 'red'
        }
      }).addTo(map)
      setIsLoading(false)
      setNumLocations(locations.length)
    }
    loadData()
  }, []) // Empty dependency array means this effect runs once on mount

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <h2 className="my-4">Workout Heatmap <small className="text-muted" hidden={!isLoading}>Loading...</small><small className="text-muted" hidden={!numLocations}>{numLocations} pools found</small></h2>
          <div
            id="heatmap"
            className="rounded shadow"
            style={{ height: '600px', width: '100%' }}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default CompletedWorkoutHeatmapComponent
