import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Parse from 'parse'
import { getSkillLevelString, getPoolCourseString, getWorkoutTypeString } from '../../Utils'
import {
  Link,
  Route
} from 'react-router-dom'
import { Table, Pagination, Button } from 'react-bootstrap'

const ITEMS_PER_PAGE = 100

export default class WorkoutTable extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      workouts: props.workouts || [],
      offset: 0,
      totalCount: 0,
      isAdmin: props.isAdmin,
      isPremium: false,
      isLoading: false,
      basePath: props.isAdmin ? '/adminworkouts/' : '/workouts/',
      className: props.isAdmin ? 'AdminWorkout' : 'Workout_v3'
    }

    this.loadWorkouts = this.loadWorkouts.bind(this)
    this.loadWorkouts()
  }

  componentWillUnmount = () => {
    window.onscroll = null
  }

  baseWorkoutQuery (skill, course, type) {
    console.log('creating base query with -- skill:' + skill + ' course:' + course + ' type:' + type)
    const workout = new Parse.Object(this.state.className)
    const query = new Parse.Query(workout)
    query.withCount(true)
    query.descending('createdAt')
    if (skill !== undefined) {
      query.equalTo('skillLevel', skill)
    }
    if (course !== undefined) {
      query.equalTo('poolCourse', course)
    }
    if (type !== undefined) {
      query.equalTo('workoutType', type)
    }

    return query
  }

  loadWorkouts = () => {
    console.log('loadWorkouts')
    const skill = this.state.skill
    const course = this.state.course
    const isPremium = this.state.isPremium
    const isAdminWorkout = this.state.isAdminWorkout
    const isLibrary = this.state.isLibrary
    const type = this.state.type
    const isDryland = this.state.isDryland
    const isTestSetWorkout = this.state.isTestSetWorkout

    let query

    if (isLibrary === true) {
      const innerQuery1 = this.baseWorkoutQuery(skill, course, type)
      const innerQuery2 = this.baseWorkoutQuery(skill, course, type)
      innerQuery1.equalTo('isPremium', true)
      innerQuery2.equalTo('isAdminWorkout', true)
      query = Parse.Query.or(innerQuery1, innerQuery2)
      query.withCount(true)
      query.include(['setgroups.sets'])
    } else {
      query = this.baseWorkoutQuery(skill, course, type)
      if (isPremium === true) {
        query.equalTo('isPremium', true)
      }
      if (isAdminWorkout === true) {
        query.equalTo('isAdminWorkout', true)
      }
    }
    if (isDryland === true) {
      query.equalTo('isAllStrength', true)
    }
    if (isTestSetWorkout === true) {
      query.equalTo('isTestSetWorkout', true)
    }

    query.limit(ITEMS_PER_PAGE)
    query.skip(this.state.offset)

    console.log(query)
    query.find().then((results) => {
      const workouts = results.results || []
      const totalCount = results.count
      if (!workouts) {
        alert('No workouts found.')
        return
      }

      console.log('got results, count: ' + workouts.length)

      const sanitizedWorkouts = workouts.map(workout => {
        return workout.toJSON()
      })

      this.setState({
        workouts: sanitizedWorkouts,
        isLoading: false,
        totalCount: totalCount
      })
    }, (error) => {
      alert('Error getting user: ' + error.message)
    })
  }

  handleNext = () => {
    this.setState({
      isLoading: true,
      offset: this.state.offset + ITEMS_PER_PAGE
    }, this.loadWorkouts)
  }

  handlePrev = () => {
    this.setState({
      isLoading: true,
      offset: this.state.offset - ITEMS_PER_PAGE
    }, this.loadWorkouts)
  }

  handleButtonClick = (event) => {
    this.setState({
      isLoading: true,
      workouts: [],
      totalCount: 0,
      offset: 0
    }, this.loadWorkouts)
  }

  handleNewWorkoutClicked = (event) => {
    event.preventDefault()
    const workout = new Parse.Object(this.state.className)
    workout.save().then(() => {
      this.props.history.push(this.state.basePath + workout.id)
    })
  }

  changePoolCourse = (event) => {
    console.log('changePoolCourse: ' + event.target.value)
    this.setState({
      course: parseInt(event.target.value)
    })
  }

  changeSkillLevel = (event) => {
    console.log('changeSkillLevel: ' + event.target.value)
    this.setState({
      skill: parseInt(event.target.value)
    })
  }

  changeWorkoutType = (event) => {
    console.log('changeWorkoutType: ' + event.target.value)
    this.setState({
      type: parseInt(event.target.value)
    })
  }

  changeIsPremium = (event) => {
    console.log('changeIsPremium: ' + event.target.value)
    this.setState({
      isPremium: event.target.checked
    })
  }

  changeIsAdminWorkout = (event) => {
    console.log('changeIsAdminWorkout: ' + event.target.value)
    this.setState({
      isAdminWorkout: event.target.checked
    })
  }

  changeIsLibrary = (event) => {
    console.log('changeIsLibrary: ' + event.target.value)
    this.setState({
      isLibrary: event.target.checked
    })
  }

  changeIsDryland = (event) => {
    console.log('changeIsDryland: ' + event.target.value)
    this.setState({
      isDryland: event.target.checked
    })
  }

  changeIsTestSetWorkout = (event) => {
    console.log('changeIsTestSetWorkout: ' + event.target.value)
    this.setState({
      isTestSetWorkout: event.target.checked
    })
  }

  render = () => {
    console.log('Rendering workout table component. isAdmin: ' + this.state.isAdmin)
    console.log('WORKOUT---', this.state.workouts)

    return (
        <div>
            <Route path={this.state.isAdmin ? '/adminworkouts' : '/workouts'}>
                <div className="container">
                    <br/>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '10px 0 30px 0' }}>
                        <h2 id="title">{this.state.isAdmin ? 'Admin ' : ''}Workout Library</h2>
                        <div className="">
                            <button type="button" className="btn btn-primary" onClick={this.handleNewWorkoutClicked}>New Workout</button>
                        </div>
                    </div>
                    <div className="mb-5 p-4" style={{ background: '#f5f5f5' }}>
                        <form id="userlookup-form" className="">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <select className="form-control" onChange={this.changePoolCourse}>
                                        <option value="-1">Course</option>
                                        <option value="0">SCY</option>
                                        <option value="2">LCM</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <select className="form-control" onChange={this.changeSkillLevel}>
                                        <option value="-1">Skill</option>
                                        <option value="0">Level 1</option>
                                        <option value="1">Level 2</option>
                                        <option value="2">Level 3</option>
                                        <option value="3">Level 4</option>
                                        <option value="4">Level 5</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                            <div className="form-group">
                                    <select className="form-control" onChange={this.changeWorkoutType}>
                                        <option value="-1">Type</option>
                                        <option value="0">Free</option>
                                        <option value="1">Distance</option>
                                        <option value="2">Sprint</option>
                                        <option value="3">Kick</option>
                                        <option value="4">IM</option>
                                        <option value="5">Fly</option>
                                        <option value="6">Back</option>
                                        <option value="7">Breast</option>
                                        <option value="8">Upper Body</option>
                                        <option value="9">Lower Body</option>
                                        <option value="10">Total Body</option>
                                        <option value="11">Shoulders</option>
                                        <option value="12">Core</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div className="flex" style={{ display: 'flex', alignItems: 'center' }}>
                                <div className="mr-4 d-flex align-items-center">
                                    <input type="checkbox" value="isPremium" onChange={this.changeIsPremium}/><span>&nbsp; isPremium</span>
                                </div>
                                <div className="mr-4 d-flex align-items-center">
                                    <input type="checkbox" value="isAdminWorkout" onChange={this.changeIsAdminWorkout}/><span>&nbsp; isAdminWorkout</span>
                                </div>
                                <div className="mr-4 d-flex align-items-center">
                                    <input type="checkbox" value="isLibrary" onChange={this.changeIsLibrary}/><span>&nbsp; Library (isPremium or isAdminWorkout)</span>
                                </div>
                                <div className="mr-4 d-flex align-items-center">
                                    <input type="checkbox" value="isDryland" onChange={this.changeIsDryland}/><span>&nbsp; Dryland</span>
                                </div>
                                <div className="mr-4 d-flex align-items-center">
                                    <input type="checkbox" value="isTestSetWorkout" onChange={this.changeIsTestSetWorkout}/><span>&nbsp; Test Set Workout</span>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-3" style={{ alignItems: 'center' }}>
                              <div className="mr-3">
                                  <button type="button" className="btn btn-primary" onClick={this.handleButtonClick}>Lookup</button>
                              </div>
                              <div className="text-success">
                                  {
                                      this.state.totalCount > 0 ? <span>{this.state.totalCount} total results</span> : null
                                  }
                              </div>
                            </div>
                        </div>
                        </form>
                    </div>
                    <div>
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Title</th>
                            <th>Distance</th>
                            <th>Level</th>
                            <th>Course</th>
                            <th>Type</th>
                            <th>Mode</th>
                            <th>Test Set</th>
                          </tr>
                        </thead>
                        <tbody> {this.state.workouts.map((workout, index) => (
                          <tr key={index}>
                            <td>{this.state.offset + index + 1}</td>
                            <td><Link to={`${this.state.basePath}${workout.objectId}`}>{workout.title || '-'}</Link></td>
                            <td>{workout.totalDistance || '-'}</td>
                            <td>{getSkillLevelString(workout.skillLevel) || '-'}</td>
                            <td>{getPoolCourseString(workout.poolCourse) || '-'}</td>
                            <td>{getWorkoutTypeString(workout.workoutType) || '-'}</td>
                            <td>{workout.isAllStrength ? 'Dryland' : 'Swim'}</td>
                            <td>{workout.isTestSetWorkout ? '🚩' : ''}</td>
                          </tr>
                        ))}
                        </tbody>
                      </Table>
                      <div className="d-flex justify-content-between align-items-center">
                        <Button variant="primary" onClick={this.handlePrev} disabled={this.state.offset === 0}>
                          Prev
                        </Button>
                        <Pagination>
                          <Pagination.Item active>{`Page ${Math.ceil(this.state.offset / ITEMS_PER_PAGE) + 1} of ${Math.ceil(this.state.totalCount / ITEMS_PER_PAGE)}`}</Pagination.Item>
                        </Pagination>
                        <Button variant="primary" onClick={this.handleNext} disabled={this.state.offset + ITEMS_PER_PAGE > this.state.totalCount}>
                          Next
                        </Button>
                      </div>
                      {this.state.isLoading ? <div className="text-center mt-3">Loading...</div> : null}
                  </div>
                </div>
            </Route>
        </div>)
  }
}
